.cards {
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: auto;
    position: absolute;
    z-index: -9;
}

.noborder {
    border: none !important;
    margin: -10px;
}

.card__img { 
    height: 100%; 
}