#about {
    width: 100%;
    padding: 200px 60px 200px 60px;
    top: 0;
}

.primaryButton {
    color: black;
    background-color: var(--pink);
    border: none;
    border-radius: 50px;
    height: 50px;
    width: 242px !important;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-in-out;
}

.primaryButton:hover {
    background-color: var(--salmon); 
    color: var(--white);
}

.boxBorder {
    border-left: 5px solid var(--negro);
}

.me-photo {
    width: 90%;
} 