:root {
    --pink: rgb(233, 189, 189);
    --salmon: rgb(236, 115, 136);
    --negro: rgb(0, 0, 0);
    --white: rgb(243, 241, 241);

    margin: 0;
    position: relative;
    /* min-height: 100vh; */
}

@font-face {
    font-family: "JetBrainsMono";
    src: local("JetBrainsMono"),
    url("./fonts/JetBrainsMono-ExtraLight.ttf") format("truetype");
    font-weight: lighter;
}

@font-face {
    font-family: "JetBrainsMono";
    src: local("JetBrainsMono"),
    url("./fonts/JetBrainsMono-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "JetBrainsMono";
    src: local("JetBrainsMono"),
    url("./fonts/JetBrainsMono-SemiBold.ttf") format("truetype");
    font-weight: bold;
}

/*  Background  */

body .gradientBackground .gradient1, 
body .gradientBackground .gradient2 {
    position: absolute;
    width: 100%;
    max-width: calc(20vw + 25%);
    height: 100%;
    max-height: calc(20vh + 25%);
    background-repeat: no-repeat;
    background-attachment: scroll;
    opacity: 1; 
}

body .gradientBackground {
    position: fixed;
    top: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-attachment: scroll;
    user-select: none;
    pointer-events: none; 
}

body .gradientBackground .gradient1 {
    top: -25%;
    left: -25%;
    background: radial-gradient(50% 50% at 50% 50%, rgb(233, 189, 189)  0%, rgba(255, 255, 255, 0) 100%);
    transform: translate(-25%, -25%) scale(2); 
}

body .gradientBackground .gradient2 {
    right: 0;
    bottom: 0;
    background: radial-gradient(50% 50% at 50% 50%, rgb(226, 202, 202) 0%, rgba(255, 255, 255, 0) 100%);
    transform: translate(50%, 50%) scale(1.5); 
}

/*  Fonts  */

.navBarText {
    font-family: "JetBrainsMono";
    font-weight: bold;
    font-size: 0.8rem !important;
    letter-spacing: 1px;
}

.buttonText {
    font-family: "JetBrainsMono";
    font-weight: bold;
    font-size: 1rem !important;
    letter-spacing: 1px;
}

.regularText {
    font-family: "JetBrainsMono";
    font-weight: lighter;
    font-size: 1rem !important;
}

.textMail {
    text-decoration: none;
    color: var(--negro);
    font-family: "JetBrainsMono";
    font-weight:normal;
    font-size: 0.8rem;
    letter-spacing: 1.5px;
}

.textMail a:hover {
    color: var(--salmon);
}

.mainTitle > h2 {
    font-family: "JetBrainsMono";
    font-weight: bold;
    font-size: 4rem;
}

.titlesH2 {
    font-family: "JetBrainsMono";
    font-weight: bold;
    font-size: 1.5rem;
}

.titlesH3 {
    font-family: "JetBrainsMono";
    font-weight: normal;
    font-size: 1.2rem !important;
}

.titlesWorks {
    font-family: "JetBrainsMono";
    font-weight: normal;
    font-size: 1.2rem !important;
    text-decoration: none;
    color: var(--negro);
}

.titlesWorks:hover {
    color: var(--salmon);
}

.fixedPositionRg {
    width: 50px !important;
    position: fixed;
    right: 25px;
    bottom: 60px;
    z-index: 1030;
}

.fixedPositionLf {
    width: 50px !important;
    position: fixed;
    left: 25px;
    bottom: 60px;
    z-index: 1030;
}

.image:hover {
    opacity: 0.7;
    cursor: pointer;
    padding-bottom: 10px;
    border-bottom: 2px solid grey;
}

/*  Switch Language  */

.form-switch .form-check-input {
    width: 56px;
    height: 25px;
    margin: 1px;
    transition: background-position .3s ease-in-out;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.368);
}

.form-check-input {
    background-color: var(--white);
    border: 1px solid rgba(125, 125, 125, 0.25);
}

.form-check-input:checked {
    background-color: var(--white);
    border: 1px solid rgba(125, 125, 125, 0.25);
}

.form-check-label {
    position: absolute;
    padding-top: 4px;
    transition: .3s ease-in-out;
}

.form-switch .form-check-input, .form-switch .form-check-input:focus {
    background-image: url('/public/assets/img/flag-england.png');
    border: 1px solid rgba(125, 125, 125, 0.25);
}

.form-switch .form-check-input:checked {
    background-image: url('/public/assets/img/flag-arg.png');
}


/*  Responsive  */

@media screen and (max-width: 1600px) {
    .me-photo {
        width: 75%;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 1200px) {
    .me-photo {
        width: 75%;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 992px) {
    .titlesH3 {
        font-size: 1.1rem !important;
    }

    .titlesH2 {
        font-size: 1.2rem;
    }

    .boxBorder {
        border-left: none;
    }

    .me-photo {
        width: 70%;
        padding-bottom: 20px;
    }

    .form-check-label {
        display: none;
    }

    .form-check {
        padding-left: 0px;
        margin-top: 10px;
    }
}

@media screen and (max-width: 768px) {
    .titlesWorks {
        font-size: 0.9rem !important;
    }
    
    .works {
        max-width: 550px;
    }

    .me-photo {
        width: 70%;
    }

    .regularText {
        font-size: 0.9rem !important;
    }
}

@media screen and (max-width: 576px) {
    #laMadriguera {
        height: 1500px;
    }

    .works {
        max-width: 450px;
    }

    .me-photo {
        width: 75%;
    }  

    .fixedPositionLf {
        position: relative;
        margin-top: 30px;
        bottom: 50px;
        left: calc(50% - 72px);
    }

    .carousel-img img {
        width: 75% !important;
        margin-bottom: 20px;
    }
}