.textMailRotate {
    transform: rotate(270deg);
}

.iconRedes {
    transition: all 0.4s ease-in-out;
}

.iconRedes:hover {
    filter: invert(50%) sepia(83%) saturate(339%) hue-rotate(300deg) brightness(97%) contrast(91%);
}