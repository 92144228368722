@keyframes float {
    0% {
        transform: translatey(10px);
    }
    50% {
        transform: translatey(-10px);
    }
    100% {
        transform: translatey(10px);
    }
}

.logo {
    width: 40px;
    margin: 10px 0;
    animation: float 6s ease-in-out infinite;
}

.navbar {
    --bs-navbar-padding-y: 0 !important;
    /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15); */
}

.navbar-nav {
    --bs-nav-link-color: var(--negro) !important;
    --bs-nav-link-hover-color: var(--salmon) !important;    
}

.navbar-nav .nav-link.active {
    color: var(--salmon) !important;
}

.container-fluid {
    --bs-gutter-x: 7rem !important;
}

.navbar {
    background: transparent;
}

.navbar.active {
    background: rgba(246, 243, 243, 0.906) !important;
}

.navbar-toggler {
    border: none !important;
}

.navbar-toggler:focus {
    box-shadow: none !important;    
}

.bg-light {
    --bs-bg-opacity: 0 !important;
}